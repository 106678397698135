import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    /* Poppins Font Faces */
    @font-face {
        font-family: "Poppins Light";
        src: url("https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrJJfecnFHGPc.woff2") format("woff2");
        font-style: normal;
    }

    @font-face {
        font-family: "Poppins Bold";
        src: url("https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrJJnecnFHGPc.woff2") format("woff2");
        font-style: normal;
    }

    /* General Styles */
    body,
    html,
    a {
        font-family: 'Poppins Light', sans-serif;
    }

    body {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        background: #A887FF; /* Light purple background */
        overflow-x: hidden;
    }

    a:hover {
        color: #25456E; /* Dark blue hover color */
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(255, 255, 255); /* White background for inputs */
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #25456E 0px 0px 0px 1px; /* Focused box shadow with dark blue */
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Poppins Bold', sans-serif;
        color: #25456E; /* Dark blue for headings */
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #25456E; /* Dark blue for paragraphs */
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #25456E; /* Base link color is dark blue */

        :hover {
            color: #1d3a5a; /* Slightly darker blue for hover effect */
        }
    }

    *:focus {
        outline: none;
    }

    /* Optional Image Styling */
    .about-block-image svg {
        text-align: center;
    }

    /* Custom Drawer Styles (for Ant Design) */
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    /* Button Styles */
    .button {
        background-color: #25456E; /* Dark blue button */
        color: white; /* White text for contrast */
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for background and transform */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .button:hover {
        background-color: #1a2e4d; /* Darker shade of blue for hover effect */
        transform: translateY(-2px); /* Slight lift effect on hover */
    }
`;
